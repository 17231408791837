import { animation, style, sequence, animate } from '@angular/animations';


export const slideFadeIn = animation(
  [
    style({ height: '0px', opacity: '0', transform: 'translateX({{slide}})' }),

    sequence([
      animate('300ms', style({ height: '*' })),
      animate('{{delay}}ms {{time}} ease-out', style({ height: '*', opacity: '1', transform: '*' }))
    ])
  ],
  { params: { time: '200ms', slide: '100px', delay: 0 } }
);

/**
 * fade out while sliding horizontally.
 * 
 * params: duration (string), slide(string 'Xpx': the ending transform of the slide) 
 */
export const slideFadeOut = animation(
  [
    style({ height: '*', opacity: '*' }),
    sequence([
      animate('{{time}}', style({ transform: 'translateX({{slide}})', opacity: 0 })),
      animate('{{delay}}ms {{time}}', style({ height: '0px' }))

    ])
  ],
  { params: { time: '200ms', slide: '100px', delay: 0 } }
);