import {Component, Inject, OnInit} from '@angular/core';
import {animate, animateChild, group, keyframes, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {slideFadeIn, slideFadeOut} from '../../slide-and-collapse.animation';
import {Subject} from 'rxjs/internal/Subject';
import {Direction} from '@angular/cdk/bidi';
import {Language, languageToDirection, Transaction} from '../../app.component';
import {LiveSettings} from '../live/live.component';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {HistoryTransactionService} from '../../services/history-transaction.service';
import {Clipboard} from '@angular/cdk/clipboard';
import * as moment from 'moment';
import {formatJewishDate, formatJewishDateInHebrew, toJewishDate} from 'jewish-date';
import {SimulatorService} from '../../services/simulator.service';
import {FormControl} from '@angular/forms';
import {LabelType, Options} from '@angular-slider/ngx-slider';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss'],
  animations: [
    trigger('enter', [
      transition(':enter', useAnimation(slideFadeIn)),
      transition(':leave', useAnimation(slideFadeOut))
    ])
    ,
    trigger('filterAnimation', [

      transition('* => *', [
        group([
          query(':enter', animateChild(), { optional: true }),
          query(':enter', [

            stagger(50, [
              animate('4s', keyframes([
                style({ backgroundColor: 'red' }),
                style({ backgroundColor: 'orange' }),
                style({ backgroundColor: 'white' })
              ]))
            ]),

          ], { optional: true }),
        ])
      ])
    ])
  ]
})
export class SimulatorComponent implements OnInit {
  public language: string;
  public direction: Direction;
  public data: Transaction[] = [];
  public last = 0;
  public ids: string;
  public liveSettings: LiveSettings;
  public header: string;
  public footer: string;
  public textToShare: string;
  public cardcolPage: string;
  public currDate = '';
  public currTime = {hour: 0, minutes: 0, seconds: 0};
  public currTime2: Date;
  public speed = 20;
  public speedControl = new FormControl(this.speed);
  speedOptions: Options = {
    floor: 1,
    ceil: 100,
    step: 10,
    rightToLeft: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
     if (value === 0){
       return '1';
     } else if (value === 100){
       return '100';
     }
     return value + '';
    }
  };
  loading = true;

  private intervals: any[] = [];
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    public simulatorService: SimulatorService,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.cardcolPage  = window.location.host;
    this.setLanguage();

    this.translate.onLangChange.subscribe(res => {
      this.setHeaders(res.lang);
      this.createTextToShare();
    });

    this.route.paramMap.subscribe(params => {
      this.ids = params.get('ids');
      this.simulatorService.ids = params.get('ids');
      const typed = params.get('typed');
      this.simulatorService.type = typed ? typed : '';

      // console.log(typed);
      this.http.get<LiveSettings>(`/api/Trans/GetHistorySetting/${this.ids}`).subscribe(res => {
        if (typed && ['prdt', 'trans'].includes(typed)){
          // @ts-ignore
          res?.liveType = typed;
        } else if (!res.liveType){
          res.liveType = 'trans';
        }
        this.liveSettings = res;
        this.simulatorService.startPeriod = new Date(res.start);
        this.simulatorService.endPeriod = new Date(res.end);
        console.log(res, this.liveSettings)
        this.simulatorService.liveSettings = res;
        // this.transactionService.initLoadLast();
        this.loading = false;
        this.simulatorService.loadFirstData();
        this.setHeaders(this.translate.currentLang);
      });
    });

    this.simulatorService.loadLastRows.subscribe(res => {
      // console.log('loadLastRows', res);
      this.last = res.data[0].idTrans;
      this.data = [...res?.data, ...this.data].slice(0, 200);
      this.setDateTime(res.data[0].date);
      // this.snackBar.open(`${res.data.length} ${res.message}`, res.action, { duration: 2000, direction: this.direction });
      // TODO להחזיר את זה בסוף
      // this.play();
    });
    this.createTextToShare();
    this.speedControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(res => {
      this.speed = res;
      if (res === 0){ res = 1; }
      this.simulatorService.setSpeed(res * 6);
    });
  }
  public setHeaders(lang){
    this.header = lang === 'he' ? this.liveSettings?.headerTextHe : this.liveSettings?.headerTextEn;
    this.footer = lang === 'he' ? this.liveSettings?.footerTextHe : this.liveSettings?.footerTextEn;
    window.document.title = lang === 'he' ? 'כארדכל | ' + this.liveSettings?.headerTextHe : 'Cardcol | ' + this.liveSettings?.headerTextEn;
  }

  public createTextToShare(){
    this.translate.get('shared.mssgToShare',{ url: this.document.URL}).subscribe(res => {
      this.textToShare = res;
    });
  }

  public play() {
    new Audio('https://live.cardcol.co.il/assets/tada.wav').play();
  }

  setDate(value: any){
    const selectedDate = new Date(value);
    console.log('setDate', selectedDate.toLocaleTimeString(), selectedDate.toLocaleDateString());
  }

  public refresh() {
    this.simulatorService.loadData.next();
    this.play();
  }

  public setLanguage(language?: Language) {
    const userLang = navigator.language.substring(0, 2);
    const setTo = language || localStorage.getItem('language') || userLang || 'he';
    this.language = setTo;
    this.direction = languageToDirection[setTo];
    this.translate.use(setTo);
    this.document.dir = languageToDirection[setTo];
    moment.locale(setTo);
    localStorage.setItem('language', setTo);
    this.setHeaders(this.language);
  }

  setDateTime(val: string) {
    const date = new Date(val);
    const res = this.simulatorService.getCurrentDate(date.getTime());
    this.currDate = res.date;
    this.currTime2 = date;
    this.currTime = {
      hour: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
    };
  }


  copyUrl() {
    this.clipboard.copy(this.document.URL);
    this.translate.get(['common.close', 'messages.urlCopy']).subscribe(res => {
      this.snackBar.open(`${res['messages.urlCopy']}`, res['common.close'], { duration: 2000, direction: this.direction });
    });
  }

}
