import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Transaction} from '../app.component';
import {LiveSettings} from '../components/live/live.component';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {delay, filter, map, retryWhen, switchMap, withLatestFrom} from 'rxjs/operators';
import {timer} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryTransactionService {
  public readonly durationSeconds = 60;
  public readonly loadLastRows = new Subject<{data: Transaction[], message: any, action: any}>();
  public readonly loadData = new Subject<void>();
  lastId = 0;
  ids: string;
  lastResponse: Transaction[] = [];
  liveSettings: LiveSettings;
  type: string= '';

  constructor(private http: HttpClient, private translate: TranslateService) {}

  getTransactions() {
    // console.log('getTransactions')
    return this.http.get<Transaction[]>(`api/Trans/GetHistoryTrans/${this.ids}/${this.lastId}/${this.type}`)
      .pipe(
        filter(x => !!x.length),
        withLatestFrom(this.translate.get('messages.new'), this.translate.get('common.close')),
        map(([data, message, action]) => {
          this.lastResponse = data.map(x => x);
          if (this.lastId === 0) {
            this.loadLastRows.next({ data , message, action});
            this.lastId = data[0].idTrans;
          } else {
            this.lastId = data[0].idTrans;
            this.proccessData(data, message, action);
          }
        }));
  }

  public loadFirstData() {
    this.http.get<Transaction[]>(`api/Trans/GetHistoryTrans/${this.ids}/${this.lastId}/${this.type}`)
      .pipe(withLatestFrom(this.translate.get('messages.new'), this.translate.get('common.close')))
      .subscribe(([data, message, action]) => {
        console.log('loadFirstData', data);
        this.lastResponse = data.map(x => x);
        this.lastId = data[0].idTrans;
        this.loadLastRows.next({ data , message, action});
        const duration = this.durationSeconds; // 10 seconds
        this.setTimerForLoadTransactions();
        // const interval = setInterval(() => {
        //   this.http.get<Transaction[]>(`api/Trans/GetHistoryTrans/${this.ids}/${this.lastId}`)
        //     .subscribe(res => {
        //       if (res?.length) {
        //         if (this.lastId === res[0].idTrans) { return; }
        //         this.lastId = res[0].idTrans;
        //         this.proccessData(res, message, action);
        //         clearInterval(interval);
        //         console.log('clear interval');
        //         this.setTimerForLoadTransactions();
        //       }
        //     });
        // }, duration * 1000);
      });
  }

  private proccessData(data: Transaction[], message: any, action: any) {
    console.log('proccessData', data);
    if (this.liveSettings.liveType !== 'prdt') {
      const duration = this.durationSeconds / (data.length + 1);
      this.notifyData(data, duration, message, action);
    } else {
      const uniqTrasactions = this.buildUniqTransactions(data);
      const duration = this.durationSeconds / (uniqTrasactions.length + 1);
      this.notifyProductsData(uniqTrasactions, duration, message, action);
    }
  }

  private buildUniqTransactions(data: Transaction[]): Array<Transaction[]> {
    const uniqTransactions = new Map();
    data.forEach(item => {
      const productsByTrans = data.filter(x => x.idTrans === item.idTrans);
      uniqTransactions.set(item.idTrans , productsByTrans);
    });
    return [...uniqTransactions.values()] as Array<Transaction[]>;
  }

  private notifyData(transactions: Transaction[], duration: number, message: any, action: any) {
    if (!transactions.length) { return; }
    let counter = transactions.length - 1;
    const interval = setInterval(() => {
      this.loadLastRows.next({ data: [transactions[counter]], message, action});
      counter--;
      if (counter < 0) { clearInterval(interval); }
    }, duration * 1000);
  }

  private notifyProductsData(nodes: Array<Transaction[]>, duration: number, message: any, action: any) {
    if (!nodes.length) {
      return;
    }
    let counter = nodes.length - 1;
    const interval = setInterval(() => {
      // console.log('interval', duration , {data: nodes[counter]}, new Date().getTime().toString());
      this.loadLastRows.next({data: nodes[counter], message, action});
      counter--;
      if (counter < 0) {
        clearInterval(interval);
      }
    }, duration * 1000);
  }

  private setTimerForLoadTransactions() {
    this.loadData.pipe(
      switchMap(() =>
        timer(0, this.durationSeconds * 1000)
          .pipe(
            switchMap(() => this.getTransactions()),
            retryWhen(x => x.pipe(delay(this.durationSeconds * 1000)))
          ))
    ).subscribe();
    this.loadData.next();
  }
}
