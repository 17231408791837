import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Direction} from '@angular/cdk/bidi';
import {Language, languageToDirection, Transaction} from '../../app.component';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Clipboard} from '@angular/cdk/clipboard';
import * as moment from 'moment/moment';
import {LiveSettings} from '../live/live.component';
import {animate, animateChild, group, keyframes, query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {slideFadeIn, slideFadeOut} from '../../slide-and-collapse.animation';
import {HistoryTransactionService} from '../../services/history-transaction.service';
import {
  toJewishDate,
  formatJewishDate,
  toHebrewJewishDate,
  formatJewishDateInHebrew,
  toGregorianDate,
  JewishMonth,
} from 'jewish-date';

@Component({
  selector: 'app-history-live',
  templateUrl: './history-live.component.html',
  styleUrls: ['./history-live.component.scss'],
  animations: [
    trigger('enter', [
      transition(':enter', useAnimation(slideFadeIn)),
      transition(':leave', useAnimation(slideFadeOut))
    ])
    ,
    trigger('filterAnimation', [

      transition('* => *', [
        group([
          query(':enter', animateChild(), { optional: true }),
          query(':enter', [

            stagger(50, [
              animate('4s', keyframes([
                style({ backgroundColor: 'red' }),
                style({ backgroundColor: 'orange' }),
                style({ backgroundColor: 'white' })
              ]))
            ]),

          ], { optional: true }),
        ])
      ])
    ])
  ]
})
export class HistoryLiveComponent implements OnInit {


  public readonly loadOrRefresh = new Subject<void>();
  public readonly loadLastRows = new Subject<void>();
  public readonly loadAmount = new Subject<void>();
  public language: string;
  public direction: Direction;
  public fields: (keyof Transaction)[] = ['date', 'cNumber', 'city', 'shopName', 'prdtName', 'Qty', 'PriceAll', 'PriceOk', 'balance'];

  public data: Transaction[] = [];
  //public last = 9847000;
  public last = 0;
  public last2balance = 0;
  public ids: string;
  public liveSettings: LiveSettings;
  public header: string;
  public footer: string;
  public totalPrdtsLastWeek = 0;
  public totalAmount: number=0;
  public textToShare: string;
  public cardcolPage: string;
  public currDate = '';
  public currTime = {hour: 0, minutes: 0, seconds: 0};
  public currTime2: Date;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private transactionService: HistoryTransactionService,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.cardcolPage  = window.location.host;
    this.setLanguage();

    this.translate.onLangChange.subscribe(res => {
      this.setHeaders(res.lang);
      this.createTextToShare();
    });

    this.route.paramMap.subscribe(params => {
      this.ids = params.get('ids');
      this.transactionService.ids = params.get('ids');
      const typed = params.get('typed');
      this.transactionService.type = typed ? typed : '';

      console.log(typed);
      this.http.get<LiveSettings>(`/api/Trans/GetSetting/${this.ids}`).subscribe(res => {
        if(typed && ['prdt', 'trans'].includes(typed)){
          // @ts-ignore
          res?.liveType = typed;
        } else if(!res.liveType){
          res.liveType = 'trans';
        }
        this.liveSettings = res;
        console.log(res, this.liveSettings)
        this.transactionService.liveSettings = res;
        // this.transactionService.initLoadLast();
        this.transactionService.loadFirstData();
        this.setHeaders(this.translate.currentLang);
      });
    });

    // this.loadLastRows.pipe(
    //   switchMap(() =>
    //     timer(0, 5000).pipe(
    //       switchMap(() =>
    //         this.http.get<number>(`/api/GetTransPrdtCountLastWeek/${this.ids}`)),
    //       retryWhen(x => x.pipe(delay(10000))),
    //       tap(x => {
    //           console.log('loadLastWeekCount', x);
    //           this.totalPrdtsLastWeek = x;
    //         }
    //       )
    //     )
    //   )).subscribe();

    // this.loadAmount.pipe(
    //   switchMap(() =>
    //     timer(0, 60000).pipe(
    //       switchMap(() =>
    //         this.http.get<number>(`/api/Trans/GetAmount/${this.ids}`)),
    //       retryWhen(x => x.pipe(delay(60000))),
    //       tap(x => {
    //           console.log('loadAmount', x);
    //           this.totalAmount = x;
    //         }
    //       )
    //     )
    //
    //   )).subscribe();

    this.transactionService.loadLastRows.subscribe(res => {
      console.log('loadLastRows', res);
      this.last = res.data[0].idTrans;
      this.data = [...res?.data, ...this.data].slice(0, 200);
      this.setDateTime(res.data[0].date);
      // this.snackBar.open(`${res.data.length} ${res.message}`, res.action, { duration: 2000, direction: this.direction });
      // TODO להחזיר את זה בסוף
      // this.play();
    });

    // this.loadLastWeekCount.next();
    // todo: delete amount
    // this.loadAmount.next();

    this.createTextToShare();
  }
  public setHeaders(lang){
    this.header = lang === 'he' ? this.liveSettings?.headerTextHe : this.liveSettings?.headerTextEn;
    this.footer = lang === 'he' ? this.liveSettings?.footerTextHe : this.liveSettings?.footerTextEn;
  }

  public createTextToShare(){
    this.translate.get('shared.mssgToShare',{ url: this.document.URL}).subscribe(res => {
      // console.log(res);
      this.textToShare = res;
    });
  }

  public play() {
    new Audio('https://live.cardcol.co.il/assets/tada.wav').play();
  }

  public refresh() {
    this.transactionService.loadData.next();
    this.loadOrRefresh.next();
    // this.loadLastWeekCount.next();
    //todo: delete amount
    //this.loadAmount.next();
    this.play();
  }

  public setLanguage(language?: Language) {
    const userLang = navigator.language.substring(0, 2);
    const setTo = language || localStorage.getItem('language') || userLang || 'he';
    this.language = setTo;
    this.direction = languageToDirection[setTo];
    this.translate.use(setTo);
    this.document.dir = languageToDirection[setTo];
    moment.locale(setTo);
    localStorage.setItem('language', setTo);
    this.setHeaders(this.language);
  }

  setDateTime(val: string) {
    const date = new Date(val);
    const jewishDate = toJewishDate(date);
    let dateString = '';
    if (this.language === 'he') {
      dateString = formatJewishDateInHebrew(jewishDate);
      console.log(dateString);
    } else {
      dateString = formatJewishDate(jewishDate);
      console.log(dateString);
    }
    this.currDate = dateString;
    this.currTime2 = date;
    this.currTime = {
      hour: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
    };
  }

  copyUrl() {
    this.clipboard.copy(this.document.URL);
    this.translate.get(['common.close', 'messages.urlCopy']).subscribe(res => {
      this.snackBar.open(`${res['messages.urlCopy']}`, res['common.close'], { duration: 2000, direction: this.direction });
    });
  }


}
