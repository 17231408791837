<ng-progress [dir]="direction + '+'"></ng-progress>

<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a class="cardcol-logo" [routerLink]="''" >
      <img src="assets/logo-cardcol.svg" />
    </a>
    <!--    <button mat-stroked-button (click)="copyUrl()">-->
    <!--      <mat-icon svgIcon="link"></mat-icon>-->
    <!--      העתק קישור לשיתוף-->
    <!--    </button>-->
<!--    <b id="title">{{header}} <span> - היסטוריה - פסח תשפ"ג</span></b>-->
    <b id="title" fxHide.xs>היסטוריית עסקאות - פסח תשפ"ג</b>
    <span class="example-spacer"></span>
    <div class="clock" fxHide.gt-xs>
      <div class="date"> {{currDate }}</div>
      <div class="time"> {{currTime2 | date : 'HH:mm:ss'}}</div>
    </div>
    <button mat-icon-button (click)="refresh()">
      <mat-icon svgIcon="refresh"></mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="languageSelection">
      <mat-icon svgIcon="translate"></mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar fxHide.gt-xs>
  <mat-toolbar-row class="mobile-title">
    <h2 id="mobile-title">
      היסטוריית עסקאות - פסח תשפ"ג
    </h2>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu class="language-selection" #languageSelection>
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let lang of ('languages' | translate)" (click)="setLanguage(lang.code)"
            [class.selected]="lang.code == language">
      {{lang.name}}
    </button>
  </ng-template>
</mat-menu>

<section fxHide.xs>
  <div fxLayout="row" class=' base-row header'>
    <div fxFlex="20" class='header-cell base-cell'>{{'fields.date' | translate}}</div>
    <div fxFlex="10" class='header-cell base-cell'>{{'fields.cNumber' | translate}}</div>
    <div fxFlex="40" class='header-cell base-cell'>{{'fields.shopName' | translate}}</div>
    <div fxFlex="10" class='header-cell base-cell'>{{'fields.city' | translate}}</div>
    <ng-container  *ngIf="liveSettings?.liveType === 'prdt'">
      <div fxFlex="40" class='header-cell base-cell'>{{'fields.prdtName' | translate}}</div>
      <div fxFlex="5" class='header-cell base-cell'>{{'fields.qty' | translate}}</div>
      <div fxFlex="20" class='header-cell base-cell'>{{'fields.priceAll' | translate}}</div>
    </ng-container>

    <div fxFlex="20" class='header-cell base-cell'>{{'fields.priceOk' | translate}}</div>
    <div fxFlex="20" class='header-cell base-cell'>{{'fields.cardBalance' | translate}}</div>
  </div>
  <div class="body" [@filterAnimation]="last">
    <div fxLayout="row" class="base-row row" *ngFor="let row of data;let index = index" mat-ripple
         [@enter]="{ value: '', params: { delay: index * 100 } }">
      <div fxFlex="20" class='cell base-cell bold'>{{row.date | amDateFormat: 'LTS'}} {{row.date | amDateFormat: 'L'}}</div>
      <div fxFlex="10" class='cell base-cell truncate'>{{row.cNumber}}*****</div>
      <div fxFlex="40" class='cell base-cell' fxHide fxShow.gt-sm [matTooltip]="row.shopName">{{row.shopName?.length >
      25 ? (row.shopName | slice : 0 : 25) + "...": (row.shopName)}}</div>
      <div fxFlex="10" class='cell base-cell truncate'>{{row.city}}</div>
      <ng-container *ngIf="liveSettings?.liveType === 'prdt'">
        <div fxFlex="40" class='cell base-cell prdtName' [matTooltip]="row.prdtName">{{! row.prdtName? ('fields.generalProduct' | translate) : row.prdtName?.length > 25 ? (row.prdtName |
          slice : 0 : 25) + "...": (row.prdtName)}}</div>
        <div fxFlex="5" class='cell base-cell prdtName'>{{row.qty}}</div>
        <div fxFlex="20" class='cell base-cell' fxHide fxShow.gt-sm>{{row.priceAll | currency : liveSettings.curr : "symbol":'1.2-2'}}
        </div>
      </ng-container>

      <div fxFlex="20" class='cell base-cell green'>{{row.priceOk | currency :liveSettings.curr : "symbol":'1.2-2'}}</div>
      <div fxFlex="20" class='cell base-cell balance' fxHide fxShow.gt-sm>{{row.balance | currency :liveSettings.curr : "symbol":'1.2-2'}}
      </div>
    </div>
  </div>
</section>
<img [src]="liveSettings?.logo" class="logo">
<mat-nav-list fxHide.gt-xs>
  <mat-list-item *ngFor="let row of data; let index = index" mat-ripple [@enter]="{ value: '', params: { delay: index * 100 } }" >
    <div mat-line id="date"><small>{{row.date | amDateFormat: 'LTS'}} {{row.date | amDateFormat: 'L'}} </small> </div>
    <div mat-line id="name"><span>{{'fields.cNumber' | translate}}</span>: <b class="truncate">{{row.cNumber}}*****</b></div>

    <div mat-line *ngIf="liveSettings?.liveType === 'prdt'"><small>{{'fields.prdtName' | translate}}</small>: <b *ngIf="!row.prdtName || !row.prdtName.length">{{'fields.generalProduct' | translate}}</b> <b *ngIf="row.prdtName && row.prdtName.length">{{row.prdtName?.length > 25 ? (row.prdtName |slice : 0 : 25) + "...":(row.prdtName)}} <small class="gray">({{row.qty | number : '1.0-2'}})</small></b></div>
    <div mat-line><small>{{'fields.shopName' | translate}}</small>: <b>{{row.shopName}}</b></div>
    <div mat-line><small>{{'fields.city' | translate}}</small>: <b>{{row.city}}</b></div>

    <mat-chip-list [dir]="'reversed' | translate">
      <mat-chip color='primary' selected>
        <div>{{row.priceAll | currency :liveSettings.curr : "symbol":'1.2-2'}} </div>
      </mat-chip>
      <mat-chip color='accent' selected  [fxHide]="!row.balance">
        <div class="balance" [fxHide]="!row.balance" ><small>{{'fields.balance' | translate}}</small><span> {{ row.balance | currency :liveSettings.curr :"symbol":'1.1-1' }}</span></div>
      </mat-chip>
    </mat-chip-list>

    <mat-divider></mat-divider>

  </mat-list-item>
</mat-nav-list>

<mat-toolbar color="accent">
  <mat-toolbar-row>
    <div>
    </div>
    <div class="row shared-section">
      <div fxHide.xs class="clock" style="position: absolute; right: 0;">
        <div class="date"> {{currDate }}</div>
        <div class="time"> {{currTime2 | date : 'HH:mm:ss'}}</div>
      </div>
      <a *ngIf="liveSettings?.linkDonate" mat-flat-button [href]="liveSettings.linkDonate" target="_blank" style="font-weight: bold">
        <span *ngIf="liveSettings.linkDonateText">{{liveSettings?.linkDonateText}}</span>
        <span *ngIf="!liveSettings.linkDonateText">לתרומה לחץ כאן</span>
      </a>
      <button mat-mini-fab color="primary" (click)="copyUrl()" matTooltip="{{'shared.copy-url' | translate}}">
        <mat-icon svgIcon="link"></mat-icon>
      </button>
      <app-email-shared-button [subject]="textToShare" [body]="textToShare"></app-email-shared-button>
      <app-whatsapp-share-button [text]="textToShare"></app-whatsapp-share-button>
    </div>

  </mat-toolbar-row>
</mat-toolbar>
