import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-email-shared-button',
  templateUrl: './email-shared-button.component.html',
  styleUrls: ['./email-shared-button.component.scss']
})
export class EmailSharedButtonComponent {
  @Input() subject: string;
  @Input() body: string;

  share() {
    let url = 'mailto:?';
    this.subject ? url += `subject=${this.subject}` : null;
    this.subject && this.body ? url += '&' : null;
    this.body ? url += `body=${this.body}` : null;
    window.open(url);
  }

}
