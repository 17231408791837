import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-whatsapp-share-button',
  templateUrl: './whatsapp-share-button.component.html',
  styleUrls: ['./whatsapp-share-button.component.scss']
})
export class WhatsappShareButtonComponent {
  @Input() text;
  constructor() { }

  share() {
    let url = 'https://api.whatsapp.com/send/?';
    url += `text=${this.text}`;
    window.open(url);
  }

}
