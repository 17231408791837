import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Transaction} from '../app.component';
import {LiveSettings} from '../components/live/live.component';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {delay, filter, map, retryWhen, switchMap, withLatestFrom} from 'rxjs/operators';
import {timer} from 'rxjs';
import { formatJewishDate, formatJewishDateInHebrew, toJewishDate} from 'jewish-date';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {
  public readonly durationSeconds = 60;
  public readonly loadLastRows = new Subject<{data: Transaction[], message: any, action: any}>();
  public readonly loadData = new Subject<void>();
  lastId = 0;
  ids: string;
  lastResponse: Transaction[] = [];
  liveSettings: LiveSettings;
  type = '';

  public startPeriod: Date;
  public endPeriod: Date;
  private currentDate: Date | null;
  public take = 300;
  public currentInterval: any;

  public setCurrentDate(date: Date) {
    this.currentDate = date;
    this.lastId = 0;
    this.loadData.next();
  }
  public setSpeed(speed: number) {
    this.lastId = 0;
    this.take = speed;
    this.loadData.next();
  }

  constructor(private http: HttpClient, private translate: TranslateService) {}

  getTransactions() {
    const body = { start: this.toDbdateTime(this.currentDate) || this.toDbdateTime(this.startPeriod), take: this.take, id: this.lastId, type: this.type };
    const url = `api/Trans/GetSimulatorTrans/${this.ids}`;
    return this.http.post<Transaction[]>(url, body)
      .pipe(
        filter(x => !!x.length),
        withLatestFrom(this.translate.get('messages.new'), this.translate.get('common.close')),
        map(([data, message, action]) => {
          this.lastResponse = data.map(x => x);
          if (this.lastId === 0) {
            // this.loadLastRows.next({ data , message, action});
            this.lastId = data[0].idTrans;
            this.proccessData(data, message, action);
          } else {
            this.lastId = data[0].idTrans;
            this.proccessData(data, message, action);
          }
        }));
  }

  public loadFirstData() {
    const body = { start: this.startPeriod, take: 100, id: this.lastId, type: this.type };
    const url = `api/Trans/GetSimulatorTrans/${this.ids}`;
    this.http.post<Transaction[]>(url, body)
      .pipe(withLatestFrom(this.translate.get('messages.new'), this.translate.get('common.close')))
      .subscribe(([data, message, action]) => {
        // console.log('loadFirstData', data);
        this.lastResponse = data.map(x => x);
        this.lastId = data[0].idTrans;
        this.loadLastRows.next({ data , message, action});
        const duration = this.durationSeconds; // 10 seconds
        this.setTimerForLoadTransactions();
      });
  }

  private proccessData(data: Transaction[], message: any, action: any) {
    // console.log('proccessData', data);
    if (this.liveSettings.liveType !== 'prdt') {
      const duration = this.durationSeconds / (data.length + 1);
      this.notifyData(data, duration, message, action);
    } else {
      const uniqTrasactions = this.buildUniqTransactions(data);
      const duration = this.durationSeconds / (uniqTrasactions.length + 1);
      this.notifyProductsData(uniqTrasactions, duration, message, action);
    }
  }

  private buildUniqTransactions(data: Transaction[]): Array<Transaction[]> {
    const uniqTransactions = new Map();
    data.forEach(item => {
      const productsByTrans = data.filter(x => x.idTrans === item.idTrans);
      uniqTransactions.set(item.idTrans , productsByTrans);
    });
    return [...uniqTransactions.values()] as Array<Transaction[]>;
  }

  private notifyData(transactions: Transaction[], duration: number, message: any, action: any) {
    if (!transactions.length) { return; }
    let counter = transactions.length - 1;
    const interval = setInterval(() => {
      this.loadLastRows.next({ data: [transactions[counter]], message, action});
      counter--;
      if (counter < 0) { clearInterval(interval); }
    }, duration * 1000);
    if (this.currentInterval){
      clearInterval(this.currentInterval);
    }
    this.currentInterval = interval;
  }

  private notifyProductsData(nodes: Array<Transaction[]>, duration: number, message: any, action: any) {
    if (!nodes.length) {
      return;
    }
    let counter = nodes.length - 1;
    const interval = setInterval(() => {
      // console.log('interval', duration , {data: nodes[counter]}, new Date().getTime().toString());
      this.loadLastRows.next({data: nodes[counter], message, action});
      counter--;
      if (counter < 0) {
        clearInterval(interval);
      }
    }, duration * 1000);
    if (this.currentInterval){
      clearInterval(this.currentInterval);
    }
    this.currentInterval = interval;
  }

  private setTimerForLoadTransactions() {
    this.loadData.pipe(
      switchMap(() =>
        timer(0, this.durationSeconds * 1000)
          .pipe(
            switchMap(() => this.getTransactions()),
            retryWhen(x => x.pipe(delay(this.durationSeconds * 1000)))
          ))
    ).subscribe();
    this.loadData.next();
  }

  toDbdateTime(date: Date){
    const pad = (n: number) => n < 10 ? '0' + n : n;
    return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + 'T' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
   }

   getLanguage() {
     const userLang = navigator.language.substring(0, 2);
     const language  = localStorage.getItem('language') || userLang || 'he';
     return language;
   }
  getCurrentDate(ticks: number){
    const date = new Date(ticks);
    const jewishDate = toJewishDate(date);

    const pad = (n: number) => n < 10 ? '0' + n : n + '';
    let dateString = '';
    let dayString = '';
    if (this.getLanguage() === 'he') {
      dateString = formatJewishDateInHebrew(jewishDate);
      dayString = dateString.split(' ')[0];
      // console.log(dateString);
    } else {
      dateString = pad(date.getDate()) + '/' + pad(date.getMonth() + 1) + '/' + pad(date.getFullYear());
      dayString = pad(date.getDate());
      // console.log(dateString);
    }

    return  {
      date: dateString,
      day: dayString,
      hour: pad(date.getHours()),
      minutes: pad(date.getMinutes()),
      seconds: pad(date.getSeconds())
    };
  }
}
