import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component';
import {LiveComponent} from './components/live/live.component';
import {HomeComponent} from './components/home/home.component';
import {HistoryLiveComponent} from './components/history-live/history-live.component';
import {SimulatorComponent} from './components/simulator/simulator.component';


const routes: Routes = [
  {
    path: 'sim/:ids/:typed',
    component: SimulatorComponent
  },
  {
    path: 'history-live/:ids/:typed',
    component: HistoryLiveComponent
  },
  {
    path: 'history-live/:ids',
    component: HistoryLiveComponent
  },
  {
    path: 'live/:ids/:typed',
    component: LiveComponent
  },
  {
    path: 'live/:ids',
    component: LiveComponent
  },
  {
    path: '',
    component: HomeComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
