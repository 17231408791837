import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressModule } from 'ngx-progressbar';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { LiveComponent } from './components/live/live.component';
import { HomeComponent } from './components/home/home.component';
import { WhatsappShareButtonComponent } from './components/globals/whatsapp-share-button/whatsapp-share-button.component';
import { EmailSharedButtonComponent } from './components/globals/email-shared-button/email-shared-button.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { HistoryLiveComponent } from './components/history-live/history-live.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
import { SimulatorNavbarComponent } from './components/simulator/sumulator-navbar/simulator-navbar.component';
import {MatSliderModule} from '@angular/material/slider';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {LayoutModule} from '@angular/cdk/layout';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=8');
}

@NgModule({
  declarations: [
    AppComponent,
    LiveComponent,
    HomeComponent,
    WhatsappShareButtonComponent,
    EmailSharedButtonComponent,
    SafeUrlPipe,
    HistoryLiveComponent,
    SimulatorComponent,
    SimulatorNavbarComponent
  ],
  imports: [
    BrowserAnimationsModule,
    MatTableModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatChipsModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatSliderModule,
    LayoutModule,
    MomentModule,
    MatIconModule,
    HttpClientModule,
    ClipboardModule,
    NgProgressModule.withConfig({spinner: false, color: 'white', thick: true}),
    NgProgressHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    AppRoutingModule,
    NgxSliderModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
