import { Direction } from '@angular/cdk/bidi';
import {Component, Inject, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';


export type Language = 'he' | 'yi' | 'en';

export const languageToDirection: Record<Language, Direction> = {
  'he': 'rtl',
  'en': 'ltr',
  'yi': 'rtl',
};

export interface Transaction {
  idTrans: number;
  date: string;
  time: string;
  cNumber: string;
  city: string;
  shopName: string;
  // barCode: string;
  prdtName: string;
  shopId: string;
  BarCode: string;
  Qty: number;
  PriceAll: number;
  PriceOk: number;
  balance: number;
  total: number;
  IsInList: boolean;
}
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private router: Router) { }

  ngOnInit() {
    this.setUpAnalytics();
  }
// cardcol gtag = G-4KR4D3RSMN
  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-4KR4D3RSMN', {
            page_path: event.urlAfterRedirects
          }
        );
        gtag('config', 'UA-86673833-1', {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }

}

