import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LabelType, Options} from '@angular-slider/ngx-slider';
import {SimulatorService} from '../../../services/simulator.service';
import {FormControl} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sumulator-navbar',
  templateUrl: './simulator-navbar.component.html',
  styleUrls: ['./simulator-navbar.component.scss']
})
export class SimulatorNavbarComponent implements OnInit {

  @Input() minDate: Date = new Date('2023-04-03');
  @Input() maxDate: Date = new Date('2023-04-04');
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  public manualRefresh = new EventEmitter<void>();

  sliderControl: FormControl;
  selectedDate: Date;
  selectedValue = 0;
  options: Options = {
    showSelectionBar: true,
    step: 30 * 60 * 1000, // 30 minutes
    translate: (value: number, label: LabelType): string => {
      const jDate = this.sim.getCurrentDate(value);
      if (value === this.minDate.getTime() || value === this.maxDate.getTime()) {
        return jDate.date;
      } else if (new Date(value).getMinutes() === 0 && new Date(value).getHours() === 0) {
        return jDate.date;
      }
      return jDate.hour + ':' + jDate.minutes;
    }
  };

  constructor(private sim: SimulatorService,
              private translate: TranslateService,
              private breakpointObserver: BreakpointObserver) {


  }

  ngOnInit(): void {
    this.sliderControl = new FormControl(this.selectedValue);
    this.selectedDate = this.minDate;
    this.options.ceil = this.maxDate.getTime();
    this.options.floor = this.minDate.getTime();
    this.options.rightToLeft = this.translate.currentLang === 'he';
    this.sliderControl.valueChanges.subscribe((value) => {
      this.selectedDate = new Date(value);
      this.onDateChange();
    });
    this.translate.onLangChange.subscribe(res => {
      this.options.rightToLeft = res.lang === 'he';
      // console.log(res);
      // console.log(this.options);
      this.options = {...this.options};
      this.refersh();
    });
    this.calculateDevices();
  }

  calculateDevices(){
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        console.log('Mobile');
        this.calculateStepper('mobile');
        this.options.step = 120 * 60 * 1000; // 2 hour
        this.options = {...this.options};
        this.refersh();
      }
    });
    this.breakpointObserver.observe([
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        console.log('Tablet');
        this.calculateStepper('tablet');
        this.options.step = 60 * 60 * 1000; // 1 hour
        this.options = {...this.options};
        this.refersh();
      }
    });
    this.breakpointObserver.observe([
      Breakpoints.Web
    ]).subscribe(result => {
      if (result.matches) {
        console.log('Desktop');
        this.calculateStepper('desktop');
        this.options.step = 30 * 60 * 1000; // 1/2 hour
        this.options = {...this.options};
        this.refersh();
      }
    });
  }

  calculateStepper(device: 'mobile' | 'tablet' | 'desktop' = 'desktop' ) {
    const timeDiff = Math.abs(this.maxDate.getTime() - this.minDate.getTime());
    const oneDayInMillis = 86400000; // = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    console.log(timeDiff);
    let tickArray = [];
    let lengthTick = 120 * 60 * 1000;
    let tickBase = 120 * 60 * 1000;
    if (device === 'mobile') {
      tickBase = 360 * 60 * 1000;
    }
    if (timeDiff <= oneDayInMillis * 3) {
      lengthTick = Math.ceil((timeDiff / oneDayInMillis) + 1) * tickBase;
      for (let i = 0; i < 13; i++) {
        tickArray.push(this.minDate.getTime() + (i * lengthTick));
      }
    } else if (device !== 'mobile' && timeDiff <= oneDayInMillis * 6) {
      lengthTick = Math.ceil((timeDiff / oneDayInMillis) + 1) * tickBase;
      for (let i = 0; i < 13; i++) {
        tickArray.push(this.minDate.getTime() + (i * lengthTick));
      }
    } else if (device === 'mobile' && timeDiff <= oneDayInMillis * 7) {
      lengthTick = 12 * 60 * 60 * 1000;
      for (let i = 0; i < Math.ceil((timeDiff / oneDayInMillis) + 1) * 2; i++) {
        tickArray.push(this.minDate.getTime() + (i * lengthTick));
      }
    } else if (device !== 'mobile' && timeDiff <= oneDayInMillis * 14) {
      lengthTick = 12 * 60 * 60 * 1000;
      for (let i = 0; i < Math.ceil((timeDiff / oneDayInMillis) + 1) * 2; i++) {
        tickArray.push(this.minDate.getTime() + (i * lengthTick));
      }
    } else {
      lengthTick = 24 * 60 * 60 * 1000;
      for (let i = 0; i < Math.ceil((timeDiff / oneDayInMillis) + 1); i++) {
        tickArray.push(this.minDate.getTime() + (i * lengthTick));
      }
    }

    this.options.ticksArray = tickArray;
    // this.options.showTicks = true;
    this.options.getLegend = (value: number): string => {
      const t = this.sim.getCurrentDate(value);
      if (t.hour === '00' && t.minutes === '00'){
        return t.hour + ':' + t.minutes + ' (' + t.day + ')';
      }
      return t.hour + ':' + t.minutes;
    };
  }

  onDateChange() {
    this.dateChange.emit(this.selectedDate);
    this.sim.setCurrentDate(this.selectedDate);
  }

  refersh() {
    setTimeout(() => {
      this.manualRefresh.emit();
    }, 200);
  }

}
